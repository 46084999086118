import { lazy, Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
// auth
import { AuthGuard } from 'src/auth/guard'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen } from 'src/components/loading-screen'
import { SubscriptionListener } from 'src/graphql'

// ----------------------------------------------------------------------

// Dashboard
const DashboardHomePage = lazy(() => import('src/pages/dashboard/home-page'))

// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'))
// FEED
const FeedPage = lazy(() => import('src/pages/dashboard/post/list'))
const PostDetailsPage = lazy(() => import('src/pages/dashboard/post/details'))

const MeetingSeriesListPage = lazy(() => import('src/pages/dashboard/meetingseries/list-v2'))
const MeetingSeriesDetailsPage = lazy(() => import('src/pages/dashboard/meetingseries/detail'))
const MeetingSeriesMeetingDetailPage = lazy(() => import('src/pages/dashboard/meetingseries/meeting-detail'))

const MeetingDetailPage = lazy(() => import('src/pages/dashboard/meetings/detail'))
const ProjectListPage = lazy(() => import('src/pages/dashboard/projects/list'))
const ProjectDetailPage = lazy(() => import('src/pages/dashboard/projects/detail'))

// ----------------------------------------------------------------------

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <SubscriptionListener>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </SubscriptionListener>
      </AuthGuard>
    ),
    children: [
      { path: 'home', element: <DashboardHomePage />, index: true },
      { path: '', element: <DashboardHomePage />, index: false },
      {
        path: 'user',
        children: [{ path: 'account', element: <UserAccountPage /> }],
      },
      {
        path: 'post',
        children: [
          { element: <FeedPage />, index: true },
          { path: 'list', element: <FeedPage /> },
          { path: ':id/:draft?', element: <PostDetailsPage /> },
        ],
      },
      {
        path: 'projects',
        children: [
          { element: <ProjectListPage />, index: true },
          { path: ':id', element: <ProjectDetailPage /> },
        ],
      },
      {
        path: 'meetingseries',
        children: [
          { element: <MeetingSeriesListPage />, index: true },
          { path: 'list', element: <MeetingSeriesListPage /> },
          { path: ':id', element: <MeetingSeriesDetailsPage /> },
          { path: ':meetingSeriesId/meetings/:id', element: <MeetingSeriesMeetingDetailPage /> },
        ],
      },
      {
        path: 'meetings',
        children: [{ path: ':id', element: <MeetingDetailPage /> }],
      },
    ],
  },
]
